<template>
  <div>
    <div class="nbanner ">
      <a :href="bannerPDF" download>
        {{bannerEntitle}}
       </a>
       <div class="swiper-container2">
        <div class="swiper-wrapper">
          <div class="swiper-slide"  v-for="(v,index) in imageUrl" :key="index">
            <div class="li">
              <h3>{{v.title}}</h3>
              <p>{{v.subtitle}}</p>
              <img  :src="v.photo" alt="">
            </div>
          </div>

       </div>
       
       <div class="swiper-pagination"></div>
      
    
    </div>
        </div>
    <div class="tabs">
      <ul class="f-no-s-center tabsTop">
       <li>
        <router-link  class="titleColor" to="/esg"  >
            战略与行动
          </router-link>
        </li>
        <li>
          <router-link  class="titleColor" to="/report" >
            报告与披露
          </router-link>
        </li>
        <li>
          <router-link  class="titleColor" to="/statement" style="color:#396aff">
            声明与制度
          </router-link>
        </li>
      </ul>
    </div>
    <div class="n-statement">
        <div class="listbox">
          <div class="title">ESG相关政策声明</div>
          <div class="itembox f-no">
            <a v-for="(item, index) in list" :key="index" :href="item.files" class="li f-no-b-center"><span v-html="item.title"></span><svg t="1708483936152" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7159" width="200" height="200"><path d="M324.3 960.1l-72.8-72.8L626.9 512 251.5 136.7l72.8-72.8L772.5 512z" p-id="7160"></path></svg></a>
          
        </div>
        <div class="botbtn" @click="change" v-if="total > pageNo">查看更多</div>
        </div>
        <div class="listbox">
          <div class="title">公司治理相关制度</div>
          <div class="itembox f-no">
            <a v-for="(item, index) in list2" :key="index" :href="item.files" class="li f-no-b-center"><span v-html="item.title"></span><svg t="1708483936152" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7159" width="200" height="200"><path d="M324.3 960.1l-72.8-72.8L626.9 512 251.5 136.7l72.8-72.8L772.5 512z" p-id="7160"></path></svg></a>
          
          </div>
          <div class="botbtn" @click="change2" v-if="total2 > pageNo2">查看更多</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['cur'],
  data() {
    return {
      pageNo: 1,
      total: 0,
      list: [],
      pageNo2:1,
      total2: 0,
      list2: [],
      active:2,
      imageUrl:'',
      bannerPDF:null,
      bannerEntitle:''
      ,
      bannerSwiper:''
    };
  },
  mounted() {
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#000");
    this.id = this.$route.query.id;
    this.selected = this.$route.query.id;
    this.getData(this.pageNo);
    this.getData2(this.pageNo2);
    this.getBanner()
    // this.initSwiper()
  },
  watch:{
    // cur(val){
    //    this.id = val
    //    this.selected = val
    //    this.getData()
    // }
  },
  methods: {
    getBanner(){
this.$fetch({
url:'index.php/esg/banner',
type:'post'
}).then(res=>{
this.imageUrl=res.data.banner_list
this.bannerPDF=res.data.banner_pdf.files
this.bannerEntitle=res.data.banner_pdf.entitle
})},
    getData() {
      this.$fetch({
         url: "index.php/esg/statement/" + this.pageNo,
        type: "post",
        data: this.$qs.stringify({
          pageNo: this.pageNo,
        }),
      }).then((res) => {
        this.total = res.data.list.count;
        this.list=[...this.list,...res.data.list.data]  ;
        this.$nextTick(() => {

          setTimeout(function(){
            this.bannerSwiper=new Swiper('.swiper-container2',{
              autoplay:3000,
              slidesPerView: 1,
              pagination : '.swiper-pagination',
        paginationClickable :true

            })
          },500)
          
        });
      });
    },
    initSwiper(){
  this.bannerSwiper=new Swiper('.swiper-container2',{
    autoplay:3000,
    slidesPerView: 1,
    pagination : '.swiper-pagination',
  }
    )
},
    change() {
      if (this.pageNo < this.total) {
        this.pageNo++;
        this.getData();
      }
    },
    getData2() {
      this.$fetch({
         url: "index.php/esg/system/"+this.pageNo2,
        type: "post",
        data: this.$qs.stringify({
          pageNo2: this.pageNo2,
        }),
      }).then((res) => {
        this.total2 = res.data.list.count;
        this.list2=[...this.list2,...res.data.list.data];
        this.$nextTick(() => {

        });
      });
    },
    change2() {
      if (this.pageNo2 < this.total2) {
        this.pageNo2++;
        this.getData2();
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
.titleColor:hover{
  color: #396aff;
  
  }
.f-no-b-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
    align-items: center;
}
.f-no-s-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

}
.tabsTop{
  left: 160px;



}
.f-no {
  display: flex;
  flex-flow: row wrap;
}
.nbanner{
  position: relative;
  overflow: hidden;
  img{
    //height: 560px;
    width:100%;
  }
  a{
    z-index: 999;
    display: block;
    background-color:#396aff;
width:180px;
color:#fff;
height: 45px;
font-size: 15px;
line-height: 45px;
text-align: center;
    position: absolute;
    left: 45%;
    top: 53%
      }
    h3{
position: absolute;
left: 20%;
font-size: 42px;
top:50%;
    }
    p{
      margin:27px 0 60px 0 ;
      position: absolute;
      left: 20%; 
font-weight: normal;
color: #c6c6c6;
font-size: 18px;
top:58%;
    }
      .swiper-pagination{
      position: absolute;
      z-index: 1;
     bottom: 20px;
      }
}
.tabs {
  background: rgb(252, 253, 253);
  font-size: 14px;
  color: #333333;
  // position: sticky;
  // top: 70px;
  z-index: 5;
  ul {
    height:70px;
    margin: 0 auto;

    li{
      margin-right:60px;
    }
    .on {
      color: #396aff;
    }
  }
}

.n-statement{
  padding:110px 0 0;
  position: relative;
  background:#fff;
  z-index:2;
  .listbox{
    width:1200px;
    margin:0 auto 100px;
    .itembox{
      justify-content: space-between;
    }
    .li{
      background:#f5f5f5;
      width:48%;
      margin-bottom:4%;
      padding:20px 35px;
      span{
        transition: all .6s;
      }
      .icon{
        width:16px;
        height:16px;
        fill:#333;
        transition: all .3s;
      }
    }
    .li:hover {
      span{
        color:#396aff;
      }
      .icon{
        fill:#396aff;
      }
    }
  }
  .title{
    font-size:30px;
    line-height:1.2;
    color:#222;
    margin-bottom:50px;
  }
  .botbtn{
    width:120px;
    height:40px;
    border:1px solid #396aff;
    color:#396aff;
    text-align:center;
    line-height:36px;
    margin:30px auto 0;
    cursor: pointer;
    transition: all .6s;
  }
  .botbtn:hover{
    background:#396aff;
    color:#fff;
  }
}


@media (min-width: 1280px) {
 
  .tabs {
    background: rgb(252, 253, 253);
    font-size: 14px;
    color: #333333;
    z-index: 5;
    ul {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      li{
        margin-right:60px;
      }
      .on {
        color: #396aff;
      }
    }
  }
  
}
</style>